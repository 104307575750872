.interclinical {
    background-image: -webkit-linear-gradient(0deg,#766dff 0%,#88f3ff 100%);
    margin-top: 60px;
    padding-bottom: 20px;
}

.interclinical > .card {
    background: transparent;
    background-color: transparent;
}

#Interclinical{
    padding-top: 60px;
    margin-top: -60px;
}