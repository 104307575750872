:root {
    --angle: 45deg;
    --opacity: 0.5;
}


.card{
    border: none !important;
    width:70%;
    margin: auto;
}

@media (min-width:1660px){
    .card {       
        width: 50%;
    }
}


.me-section{
    background-image: -webkit-linear-gradient(0deg,#766dff 0%,#88f3ff 100%);
    z-index: 1;
    padding-top: 80px;
    padding-bottom: 80px;
}

.me-section > .card{
    margin-top: 60px;
}

#Me{
    padding-top: 60px;
    margin-top: -60px;
}

#email, #mobile{
    color: #007bff;
    cursor: pointer;
}

#location{
    color: #007bff;
}

.myImage{
    position: relative;
    border-radius: 8px;
    width:100%;
    overflow: hidden;
}

#myProfile{
    margin-top: 30px;
}

.circle {
    width: 100%;
    position: relative;
}

.circle .colouredBorder {
    --border-size: 3px;
    --border-angle: 0turn;
    width: 100%;
    height: auto;
    background-image: conic-gradient( from var(--border-angle), transparent, transparent 50%, transparent ), conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
    background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
    background-position: center center;
    background-repeat: no-repeat;
    animation: bg-spin 3s linear infinite;
    padding: 4px;
}

.card-body .order-md-2{
    margin-top: auto;
    margin-bottom: auto;
}

.card-body .order-md-1{
    padding-right: 50px;
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}

@media(max-width: 767px){
	.order-md-2{
 		padding-top: 10px;
    	padding-bottom: 10px;
	}
}
