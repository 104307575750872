.dropdown-menu {
left: -130% !important;
}

.navbar-light{
   background: transparent;
   background-color: transparent;
}


.slideIn {
    background-color: white;
    transition: all .4s ease-in-out;
}

@media(min-width: 1060px){
    .nav-item{
        padding-left: .5rem;
    }
}

@media(min-width:992px) and  (max-width:1059px)
{
    .nav-item{
        padding-right: 2px;
    }
}

.nav-item > a {
    color: black;
}

.navbar-brand{
    color: white !important;
}

.header_area {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    transition: background 0.4s, all 0.3s linear; }
    .header_area .navbar {
      background: transparent;
      padding: 0px;
      border: 0px;
      border-radius: 0px; }
      .header_area .navbar .nav .nav-item {
        margin-right: 45px; }
        .header_area .navbar .nav .nav-item .nav-link {
          font: 500 12px/120px "Roboto", sans-serif;
          text-transform: uppercase;
          color: #fff;
          padding: 0px;
          display: inline-block; }
          .header_area .navbar .nav .nav-item .nav-link:after {
            display: none; }
        .header_area .navbar .nav .nav-item:hover .nav-link, .header_area .navbar .nav .nav-item.active .nav-link {
          color: #fff; }
        .header_area .navbar .nav .nav-item.submenu {
          position: relative; }
          .header_area .navbar .nav .nav-item.submenu ul {
            border: none;
            padding: 0px;
            border-radius: 0px;
            box-shadow: none;
            margin: 0px;
            background: #fff;
            box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1); }
            @media (min-width: 992px) {
              .header_area .navbar .nav .nav-item.submenu ul {
                position: absolute;
                top: 120%;
                left: 0px;
                min-width: 200px;
                text-align: left;
                opacity: 0;
                transition: all 300ms ease-in;
                visibility: hidden;
                display: block;
                border: none;
                padding: 0px;
                border-radius: 0px; } }
            .header_area .navbar .nav .nav-item.submenu ul:before {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 10px 10px 0 10px;
              border-color: #eeeeee transparent transparent transparent;
              position: absolute;
              right: 24px;
              top: 45px;
              z-index: 3;
              opacity: 0;
              transition: all 400ms linear; }
            .header_area .navbar .nav .nav-item.submenu ul .nav-item {
              display: block;
              float: none;
              margin-right: 0px;
              border-bottom: 1px solid #ededed;
              margin-left: 0px;
              transition: all 0.4s linear; }
              .header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
                line-height: 45px;
                color: #222222;
                padding: 0px 30px;
                transition: all 150ms linear;
                display: block;
                margin-right: 0px; }
              .header_area .navbar .nav .nav-item.submenu ul .nav-item:last-child {
                border-bottom: none; }
              .header_area .navbar .nav .nav-item.submenu ul .nav-item:hover .nav-link {
                background: #766dff;
                color: #fff; }
          @media (min-width: 992px) {
            .header_area .navbar .nav .nav-item.submenu:hover ul {
              visibility: visible;
              opacity: 1;
              top: 100%; } }
          .header_area .navbar .nav .nav-item.submenu:hover ul .nav-item {
            margin-top: 0px; }
        .header_area .navbar .nav .nav-item:last-child {
          margin-right: 0px; }
      .header_area .navbar .search {
        font-size: 12px;
        line-height: 60px;
        display: inline-block;
        color: #222222;
        margin-left: 80px; }
        .header_area .navbar .search i {
          font-weight: 600; }
    .header_area.navbar_fixed .main_menu {
      position: fixed;
      width: 100%;
      top: -70px;
      left: 0;
      right: 0;
      background: #000;
      transform: translateY(70px);
      transition: transform 500ms ease, background 500ms ease;
      -webkit-transition: transform 500ms ease, background 500ms ease;
      box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1); }
      .header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
        line-height: 70px; }
  
  .top_menu {
    background: #f9f9ff; }
    .top_menu .header_social li {
      display: inline-block;
      margin-right: 15px; }
      .top_menu .header_social li a {
        font-size: 14px;
        color: #777777;
        display: inline-block;
        line-height: 42px;
        transition: all 300ms linear 0s; }
      .top_menu .header_social li:last-child {
        margin-right: 0px; }
      .top_menu .header_social li:hover a {
        color: #766dff; }
    .top_menu .dn_btn {
      line-height: 42px;
      display: inline-block;
      font-size: 12px;
      margin-right: 30px;
      font-family: "Roboto", sans-serif;
      font-weight: normal;
      color: #777777;
      transition: all 300ms linear 0s; }
      .top_menu .dn_btn:hover {
        color: #766dff; }
      .top_menu .dn_btn:last-child {
        margin-right: 0px; }
    .top_menu .lan_pack {
      height: 30px;
      border: 1px solid #eeeeee;
      border-radius: 3px;
      line-height: 28px;
      font-size: 12px;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      padding-left: 19px;
      padding-right: 36px;
      color: #777777;
      background: #f9f9ff;
      margin-right: 5px;
      margin-top: 8px; }
      .top_menu .lan_pack .current {
        color: #777777; }
      .top_menu .lan_pack:after {
        content: "\f0d7";
        border: none !important;
        font: normal normal normal 12px/1 FontAwesome;
        transform: rotate(0deg);
        height: auto;
        margin-top: -6px;
        right: 20px; }

        @media (max-width:991px){
          /* Main Menu Area css
          ============================================================================================ */
            .navbar-toggler {
                border: none;
                border-radius: 0px;
                padding: 0px;
                cursor: pointer;
                margin-top: 27px;
                margin-bottom: 23px;
            }
          .header_area .navbar {
            background: #000;
          }
            .navbar-toggler[aria-expanded="false"] span:nth-child(2) {
            opacity: 1;
          }
          .navbar-toggler[aria-expanded="true"] span:nth-child(2) {
            opacity: 0;
          }
          .navbar-toggler[aria-expanded="true"] span:first-child {
            transform: rotate(-45deg);
            position: relative;
            top: 7.5px;
          }
          .navbar-toggler[aria-expanded="true"] span:last-child {
            transform: rotate(45deg);
            bottom: 6px;
            position: relative;
          }
          .navbar-toggler span{
            display: block;
            width: 25px;
            height: 3px;
            background: #fff;
            margin: auto;
            margin-bottom: 4px;
            transition: all 400ms linear;
            cursor: pointer;
          }
            .navbar .container{
                padding-left: 15px;
                padding-right: 15px;
            }
            .nav{
                padding: 0px 0px;
            }
          .header_area + section, .header_area + row, .header_area + div {
            margin-top: 117px;
          }
            .header_top .nav{
                padding: 0px;
            }
            .header_area .navbar .nav .nav-item .nav-link{
                line-height: 40px;
                margin-right: 0px;
                display: block;
            border-bottom: 1px solid #ededed33;
            border-radius: 0px;
            }
          .header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
            line-height: 40px;
          }
            .header_area .navbar .search{
                margin-left: 0px;
            }
          .header_area .navbar-collapse{
            max-height: 340px;
            overflow-y: scroll;
          }
          .header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
            padding: 0px 15px;
          }
          .header_area .navbar .nav .nav-item {
            margin-right: 0px;
          }
          .header_area + section, .header_area + row, .header_area + div {
            margin-top: 0px;
          }
      }

@media (max-width: 991.98px){
.navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
    padding-left: 15px !important;
}
}


ul li a:hover::before {
  transform: scale(1.1);
}

ul li a:hover {
  color: #FF00FF;
  text-shadow: 0 0 5px #FF00FF;
}

.logo_h:hover{
  color: #FF00FF;
  text-shadow: 0 0 5px #FF00FF;
}