.tmphysio-image{
    width:100%;
}


.tmphysio-section{
    background-image: -webkit-linear-gradient(0deg,#766dff 0%,#88f3ff 100%);
    margin-top: 60px;
    padding-bottom: 20px;
}

.tmphysio-section > .card {
    background: transparent;
    background-color: transparent;
}


.tmphysio-section h5, span {
    color: white;
}

.text-white {
    color: white;
}


.pt-4, .py-4 {
    padding-top: 0px !important; 
}

.mt-4, .my-4 {
    margin-top: 0px !important; 
}

#TMPhysio{
    padding-top: 60px;
    margin-top: -60px;
}

.tmphysio-images .awssld__container {
    padding-bottom: 35%;
}

.tmphysio-images{
    margin-bottom: 40px;
}