#Employment{
    padding-top: 60px;
    margin-top: -60px;
}


.employment{
    background-image: -webkit-linear-gradient(0deg,#766dff 0%,#88f3ff 100%);
}


.media-body {
    vertical-align: middle;
    align-self: center; 
}

#employment-heading{
    color: white;
}


.tasks  li {
    padding-bottom: 0.8em;
    font-size: 1.1em;
    text-align: left !important;
}

.linksForExamples a{
    text-decoration: none;
    color: #007bff;
    font-size: 16px;
}


@media(min-width:1200px){
    .vertical-timeline-element-date{
        color: white !important;
    }
}