.section-listing {
    list-style: none;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill,minmax(380px,1fr));
    padding-top: 4%;
}

.center-sections{
    margin-left: auto;
    margin-right: auto;
    width:100%;
    padding-bottom: 100px;
}

.grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }


@media(min-width: 967px){
    
    .card-img-top img {
        height: 130px;
    }
}

@media(max-width: 967px){

    .section-crd-bdy > h3 {
        height: 3.2em !important;
    }
    .section-listing {
        grid-template-columns: repeat(auto-fill,minmax(300px,1fr)) !important;
        
    }
}

#My-Projects{
    padding-top: 60px;
    margin-top: -60px;
}

a.section-card {
    text-decoration: none!important;
    color: #000;
}

#section-heading{
    padding-top: 20px;
    color: #007bff;
}

.section-crd-bdy {
    height: 15em;
}

.section-crd-bdy > h3 {
    height: 2.5em;
}

.center-block{
    text-align: center;
}

.section-listing p{
    color: black;
}

.card-listing {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
    padding: 16px;
    text-align: center;
    background-color: white;
    border: none !important;
    width: 80%;
    margin: auto;
}

.card-listing:hover {
    box-shadow: 20px 20px 40px 0px rgba(0,0,0,0.5);
    background-color: #ace4fd;
  }