.ClearView-images {
    margin-bottom: 40px;
}

.ClearView{
    margin-top: 60px;
    padding-bottom: 20px;
}

#ClearView{
    padding-top: 60px;
    margin-top: -60px;
}

.ClearView-images .awssld__container {
    padding-bottom: 50%;
}

.ClearView-images{
    margin-bottom: 40px;
}
