html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


p{
  text-align: justify;
}

.card-title{
  color: #007bff
}

.weblink{
  color: #00fdff
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #8A2BE2; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #FF00FF; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #EE82EE; 
}


.scroll-your-role{
  z-index: 99;
  font-size: 18px;
  border: none !important;
  outline: none !important;
  background-color:  #8A2BE2 !important;
  color: white;
  padding: 15px !important;
}


@media (max-width: 967px){
  .awssld__content > div {
      font-size: 10px;
      height: 20px;
      overflow: hidden;
  }		  
}

.react-scrolltop-button > svg {
  position: relative;
  left: -1px;
}