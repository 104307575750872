.metromix-images {
    margin-bottom: 40px;
}

.metromix{
    margin-top: 60px;
    padding-bottom: 20px;
}

#Metromix{
    padding-top: 60px;
    margin-top: -60px;
}

.metromix-images .awssld__container {
    padding-bottom: 50%;
}

.metromix-images{
    margin-bottom: 40px;
}