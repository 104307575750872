.react-images__navigation button {
    color: black;
    background: rgba(255, 255, 255, 0.8);
}


.react-images__navigation button:hover {
    color: black;
    background: rgba(255, 255, 255, 1);
}

#Mega{
    padding-top: 60px;
    margin-top: -60px;
}

#megaSection{
    margin-bottom: 120px;
    margin-top: 60px;
}