.sublime {
    background-image: -webkit-linear-gradient(0deg,#766dff 0%,#88f3ff 100%);
    margin-top: 60px;
    padding-bottom: 20px;
}

.sublime > .card {
    background: transparent;
    background-color: transparent;
}

#Sublime{
    padding-top: 60px;
    margin-top: -60px;
}

.Sublime-images .awssld__container {
    padding-bottom: 45%;
}

.Sublime-images{
    margin-bottom: 40px;
}