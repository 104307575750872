.sproutLongImages{
    height: 500px;
}

.sproutWideImages{
    width: 100%;
    height: 230px;
    object-fit: contain;
}

.SproutZoom{
    width: 100%;
    height: auto !important;
}

.center{
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-bottom: 5%;
}

.sprout{
    background-image: -webkit-linear-gradient(0deg,#766dff 0%,#88f3ff 100%);
    padding-bottom: 20px;
}

.sprout > .card {
    background: transparent;
    background-color: transparent;
}


.sprout h5, span {
    color: white;
}

.text-white {
    color: white;
}

#Sprout{
    padding-top: 60px;
    margin-top: -60px;
}

.sprout-images .awssld__container {
    padding-bottom: 50%;
}

.sprout-images{
    margin-bottom: 40px;
}